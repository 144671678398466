import Firebase from 'firebase/compat/app'
import ReactGA from 'react-ga'
import { SodaUser, transformFirebaseUsertoSodaUser } from '../../models/user/UserModel'

export const signIn = () => {
  console.log('sign in')
  ReactGA.event({
    category: 'User',
    action: 'Log in',
  })

  const provider = new Firebase.auth.GoogleAuthProvider()

  return Firebase.auth()
    .signInWithPopup(provider)
    .then(async value => {
      await addUser(value.user ?? undefined)
    }).catch(error => {
      console.error('could not sign in', error)
    })
}

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: 'https://dev.firefanfic.com/',
  // This must be true.
  handleCodeInApp: true,
  iOS: {
    bundleId: 'com.example.ios'
  },
  android: {
    packageName: 'com.example.android',
    installApp: true,
    minimumVersion: '12'
  },
  dynamicLinkDomain: 'example.page.link'
};

export const signInWithEmailLink = (email: string) => {
return Firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
  .then(() => {
    // The link was successfully sent. Inform the user.
    // Save the email locally so you don't need to ask the user for it again
    // if they open the link on the same device.
    window.localStorage.setItem('emailForSignIn', email);
    // ...
  })
  .catch((error) => {
    console.error('could not sign in', error)
  });

}

export const signInWithEmail = (
  email: string, password: string,
) => {
  console.log('sign in with email password')
  ReactGA.event({
    category: 'User',
    action: 'Log in',
  })

  return Firebase.auth()
    .createUserWithEmailAndPassword(email, password)
    .then(async value => {
      await addUser(value.user ?? undefined)
      Firebase.auth().signInWithEmailLink
    }).catch(error => {
      console.error('could not sign in', error)
    })
}

export const addUser = async (user?: Firebase.User): Promise<void | SodaUser> => {
  if (user) {
    const userDocRef = Firebase.firestore()
      .collection('users')
      .doc(user.uid);
    const doc = await userDocRef.get();

    if (!doc.exists) {
      const newUser = transformFirebaseUsertoSodaUser(user);
      return Firebase.firestore()
        .collection('users')
        .doc(user.uid)
        .set(newUser)
        .then(() => newUser)
        .catch(error => {
          alert(`Whoops, couldn't create the user: ${error.message}`)
        })
    }
  }

}
