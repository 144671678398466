import React from 'react';
import Header from '../../components/header/Header';
import { View } from '../../components/layout/styles';

const VerifyEmailView = () => {
  return (
    <>
      <Header />
      <View></View>
    </>
  );
};

export default VerifyEmailView;
